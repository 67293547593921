export const IoSocketStatus = () => import('../../node_modules/nuxt-socket-io/lib/components/SocketStatus.js' /* webpackChunkName: "components/io-socket-status" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumbs = () => import('../../components/BreadCrumbs.vue' /* webpackChunkName: "components/bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const CardDeposit = () => import('../../components/CardDeposit.vue' /* webpackChunkName: "components/card-deposit" */).then(c => wrapFunctional(c.default || c))
export const CardTitleAndCaption = () => import('../../components/CardTitleAndCaption.vue' /* webpackChunkName: "components/card-title-and-caption" */).then(c => wrapFunctional(c.default || c))
export const CardWithCurrency = () => import('../../components/CardWithCurrency.vue' /* webpackChunkName: "components/card-with-currency" */).then(c => wrapFunctional(c.default || c))
export const CardWithIconAndTitle = () => import('../../components/CardWithIconAndTitle.vue' /* webpackChunkName: "components/card-with-icon-and-title" */).then(c => wrapFunctional(c.default || c))
export const CardWithIconTitleCurrency = () => import('../../components/CardWithIconTitleCurrency.vue' /* webpackChunkName: "components/card-with-icon-title-currency" */).then(c => wrapFunctional(c.default || c))
export const ComingSoon = () => import('../../components/ComingSoon.vue' /* webpackChunkName: "components/coming-soon" */).then(c => wrapFunctional(c.default || c))
export const CopyButton = () => import('../../components/CopyButton.vue' /* webpackChunkName: "components/copy-button" */).then(c => wrapFunctional(c.default || c))
export const CurrencyTextfield = () => import('../../components/CurrencyTextfield.vue' /* webpackChunkName: "components/currency-textfield" */).then(c => wrapFunctional(c.default || c))
export const CustomAutocomplete = () => import('../../components/CustomAutocomplete.vue' /* webpackChunkName: "components/custom-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const CustomCheckbox = () => import('../../components/CustomCheckbox.vue' /* webpackChunkName: "components/custom-checkbox" */).then(c => wrapFunctional(c.default || c))
export const CustomImage = () => import('../../components/CustomImage.vue' /* webpackChunkName: "components/custom-image" */).then(c => wrapFunctional(c.default || c))
export const CustomToaster = () => import('../../components/CustomToaster.vue' /* webpackChunkName: "components/custom-toaster" */).then(c => wrapFunctional(c.default || c))
export const CustomTooltip = () => import('../../components/CustomTooltip.vue' /* webpackChunkName: "components/custom-tooltip" */).then(c => wrapFunctional(c.default || c))
export const DateButtonFilter = () => import('../../components/DateButtonFilter.vue' /* webpackChunkName: "components/date-button-filter" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../components/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const DeviceIcons = () => import('../../components/DeviceIcons.vue' /* webpackChunkName: "components/device-icons" */).then(c => wrapFunctional(c.default || c))
export const ExchangeRate = () => import('../../components/ExchangeRate.vue' /* webpackChunkName: "components/exchange-rate" */).then(c => wrapFunctional(c.default || c))
export const FilterForm = () => import('../../components/FilterForm.vue' /* webpackChunkName: "components/filter-form" */).then(c => wrapFunctional(c.default || c))
export const GoBack = () => import('../../components/GoBack.vue' /* webpackChunkName: "components/go-back" */).then(c => wrapFunctional(c.default || c))
export const ImageUploadDragDrop = () => import('../../components/ImageUploadDragDrop.vue' /* webpackChunkName: "components/image-upload-drag-drop" */).then(c => wrapFunctional(c.default || c))
export const IsOffline = () => import('../../components/IsOffline.vue' /* webpackChunkName: "components/is-offline" */).then(c => wrapFunctional(c.default || c))
export const LaoThaiUSD = () => import('../../components/LaoThaiUSD.vue' /* webpackChunkName: "components/lao-thai-u-s-d" */).then(c => wrapFunctional(c.default || c))
export const LoadingLanguageString = () => import('../../components/LoadingLanguageString.vue' /* webpackChunkName: "components/loading-language-string" */).then(c => wrapFunctional(c.default || c))
export const MonthPicker = () => import('../../components/MonthPicker.vue' /* webpackChunkName: "components/month-picker" */).then(c => wrapFunctional(c.default || c))
export const NuxtImage = () => import('../../components/NuxtImage.vue' /* webpackChunkName: "components/nuxt-image" */).then(c => wrapFunctional(c.default || c))
export const Phase2Alert = () => import('../../components/Phase2Alert.vue' /* webpackChunkName: "components/phase2-alert" */).then(c => wrapFunctional(c.default || c))
export const PhoneNumberInput = () => import('../../components/PhoneNumberInput.vue' /* webpackChunkName: "components/phone-number-input" */).then(c => wrapFunctional(c.default || c))
export const PlatformIcon = () => import('../../components/PlatformIcon.vue' /* webpackChunkName: "components/platform-icon" */).then(c => wrapFunctional(c.default || c))
export const PlayerFilter = () => import('../../components/PlayerFilter.vue' /* webpackChunkName: "components/player-filter" */).then(c => wrapFunctional(c.default || c))
export const PlayerSearch = () => import('../../components/PlayerSearch.vue' /* webpackChunkName: "components/player-search" */).then(c => wrapFunctional(c.default || c))
export const QuotaWarning = () => import('../../components/QuotaWarning.vue' /* webpackChunkName: "components/quota-warning" */).then(c => wrapFunctional(c.default || c))
export const SearchOperatorPlayer = () => import('../../components/SearchOperatorPlayer.vue' /* webpackChunkName: "components/search-operator-player" */).then(c => wrapFunctional(c.default || c))
export const SearchPlayer = () => import('../../components/SearchPlayer.vue' /* webpackChunkName: "components/search-player" */).then(c => wrapFunctional(c.default || c))
export const TimePicker = () => import('../../components/TimePicker.vue' /* webpackChunkName: "components/time-picker" */).then(c => wrapFunctional(c.default || c))
export const TitleAndSubtitle = () => import('../../components/TitleAndSubtitle.vue' /* webpackChunkName: "components/title-and-subtitle" */).then(c => wrapFunctional(c.default || c))
export const TwoStepVerifyOTP = () => import('../../components/TwoStepVerifyOTP.vue' /* webpackChunkName: "components/two-step-verify-o-t-p" */).then(c => wrapFunctional(c.default || c))
export const VerifyOtpDialog = () => import('../../components/VerifyOtpDialog.vue' /* webpackChunkName: "components/verify-otp-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdminSearchAdminName = () => import('../../components/admin/SearchAdminName.vue' /* webpackChunkName: "components/admin-search-admin-name" */).then(c => wrapFunctional(c.default || c))
export const AffiliateAddBank = () => import('../../components/affiliate/AddBank.vue' /* webpackChunkName: "components/affiliate-add-bank" */).then(c => wrapFunctional(c.default || c))
export const AgentAddBank = () => import('../../components/agent/AddBank.vue' /* webpackChunkName: "components/agent-add-bank" */).then(c => wrapFunctional(c.default || c))
export const AgentUpdateSettlementStatusButton = () => import('../../components/agent/UpdateSettlementStatusButton.vue' /* webpackChunkName: "components/agent-update-settlement-status-button" */).then(c => wrapFunctional(c.default || c))
export const BannerAccountAgeChart = () => import('../../components/banner/AccountAgeChart.vue' /* webpackChunkName: "components/banner-account-age-chart" */).then(c => wrapFunctional(c.default || c))
export const BannerCountryCodeSearch = () => import('../../components/banner/CountryCodeSearch.vue' /* webpackChunkName: "components/banner-country-code-search" */).then(c => wrapFunctional(c.default || c))
export const BannerDeleteBannerButton = () => import('../../components/banner/DeleteBannerButton.vue' /* webpackChunkName: "components/banner-delete-banner-button" */).then(c => wrapFunctional(c.default || c))
export const BannerDeleteCategoryButton = () => import('../../components/banner/DeleteCategoryButton.vue' /* webpackChunkName: "components/banner-delete-category-button" */).then(c => wrapFunctional(c.default || c))
export const BannerMultipleBar = () => import('../../components/banner/MultipleBar.vue' /* webpackChunkName: "components/banner-multiple-bar" */).then(c => wrapFunctional(c.default || c))
export const BannerMultipleLines = () => import('../../components/banner/MultipleLines.vue' /* webpackChunkName: "components/banner-multiple-lines" */).then(c => wrapFunctional(c.default || c))
export const BannerPlayerChart = () => import('../../components/banner/PlayerChart.vue' /* webpackChunkName: "components/banner-player-chart" */).then(c => wrapFunctional(c.default || c))
export const BannerStatusBannerUpdate = () => import('../../components/banner/StatusBannerUpdate.vue' /* webpackChunkName: "components/banner-status-banner-update" */).then(c => wrapFunctional(c.default || c))
export const ButtonsUpdateButton = () => import('../../components/buttons/UpdateButton.vue' /* webpackChunkName: "components/buttons-update-button" */).then(c => wrapFunctional(c.default || c))
export const BetResultType = () => import('../../components/bet/BetResultType.vue' /* webpackChunkName: "components/bet-result-type" */).then(c => wrapFunctional(c.default || c))
export const BetStatus = () => import('../../components/bet/BetStatus.vue' /* webpackChunkName: "components/bet-status" */).then(c => wrapFunctional(c.default || c))
export const BetPayoutDetail = () => import('../../components/bet/PayoutDetail.vue' /* webpackChunkName: "components/bet-payout-detail" */).then(c => wrapFunctional(c.default || c))
export const DashboardGGRAndTurnoverChartInDay = () => import('../../components/dashboard/GGRAndTurnoverChartInDay.vue' /* webpackChunkName: "components/dashboard-g-g-r-and-turnover-chart-in-day" */).then(c => wrapFunctional(c.default || c))
export const DashboardGGRAndTurnoverChartInMonth = () => import('../../components/dashboard/GGRAndTurnoverChartInMonth.vue' /* webpackChunkName: "components/dashboard-g-g-r-and-turnover-chart-in-month" */).then(c => wrapFunctional(c.default || c))
export const DashboardGGRAndTurnoverChartInYear = () => import('../../components/dashboard/GGRAndTurnoverChartInYear.vue' /* webpackChunkName: "components/dashboard-g-g-r-and-turnover-chart-in-year" */).then(c => wrapFunctional(c.default || c))
export const DashboardMultipleChart = () => import('../../components/dashboard/MultipleChart.vue' /* webpackChunkName: "components/dashboard-multiple-chart" */).then(c => wrapFunctional(c.default || c))
export const DashboardMultipleChartInHours = () => import('../../components/dashboard/MultipleChartInHours.vue' /* webpackChunkName: "components/dashboard-multiple-chart-in-hours" */).then(c => wrapFunctional(c.default || c))
export const DashboardPlayerAgeDemographic = () => import('../../components/dashboard/PlayerAgeDemographic.vue' /* webpackChunkName: "components/dashboard-player-age-demographic" */).then(c => wrapFunctional(c.default || c))
export const DashboardPlayerChartInDay = () => import('../../components/dashboard/PlayerChartInDay.vue' /* webpackChunkName: "components/dashboard-player-chart-in-day" */).then(c => wrapFunctional(c.default || c))
export const DashboardPlayerChartInHours = () => import('../../components/dashboard/PlayerChartInHours.vue' /* webpackChunkName: "components/dashboard-player-chart-in-hours" */).then(c => wrapFunctional(c.default || c))
export const DashboardPlayerChartInMonth = () => import('../../components/dashboard/PlayerChartInMonth.vue' /* webpackChunkName: "components/dashboard-player-chart-in-month" */).then(c => wrapFunctional(c.default || c))
export const DashboardPlayerChartInYear = () => import('../../components/dashboard/PlayerChartInYear.vue' /* webpackChunkName: "components/dashboard-player-chart-in-year" */).then(c => wrapFunctional(c.default || c))
export const DashboardPlayerFinancialInformation = () => import('../../components/dashboard/PlayerFinancialInformation.vue' /* webpackChunkName: "components/dashboard-player-financial-information" */).then(c => wrapFunctional(c.default || c))
export const DashboardPlayerInHours = () => import('../../components/dashboard/PlayerInHours.vue' /* webpackChunkName: "components/dashboard-player-in-hours" */).then(c => wrapFunctional(c.default || c))
export const DashboardRewardPlayerFinancialInformation = () => import('../../components/dashboard/RewardPlayerFinancialInformation.vue' /* webpackChunkName: "components/dashboard-reward-player-financial-information" */).then(c => wrapFunctional(c.default || c))
export const DashboardWalletExpense = () => import('../../components/dashboard/WalletExpense.vue' /* webpackChunkName: "components/dashboard-wallet-expense" */).then(c => wrapFunctional(c.default || c))
export const DepositAutoTopupStatus = () => import('../../components/deposit/AutoTopupStatus.vue' /* webpackChunkName: "components/deposit-auto-topup-status" */).then(c => wrapFunctional(c.default || c))
export const DepositLockDepositRequest = () => import('../../components/deposit/LockDepositRequest.vue' /* webpackChunkName: "components/deposit-lock-deposit-request" */).then(c => wrapFunctional(c.default || c))
export const DepositNewDeposit = () => import('../../components/deposit/NewDeposit.vue' /* webpackChunkName: "components/deposit-new-deposit" */).then(c => wrapFunctional(c.default || c))
export const DepositPendingDeposit = () => import('../../components/deposit/PendingDeposit.vue' /* webpackChunkName: "components/deposit-pending-deposit" */).then(c => wrapFunctional(c.default || c))
export const DrawerRightDrawer = () => import('../../components/drawer/RightDrawer.vue' /* webpackChunkName: "components/drawer-right-drawer" */).then(c => wrapFunctional(c.default || c))
export const FinanceCheckSelfGatewayBalance = () => import('../../components/finance/CheckSelfGatewayBalance.vue' /* webpackChunkName: "components/finance-check-self-gateway-balance" */).then(c => wrapFunctional(c.default || c))
export const FinanceCreateInternationalSelfManaged = () => import('../../components/finance/CreateInternationalSelfManaged.vue' /* webpackChunkName: "components/finance-create-international-self-managed" */).then(c => wrapFunctional(c.default || c))
export const FinanceCreateLaoSelfManaged = () => import('../../components/finance/CreateLaoSelfManaged.vue' /* webpackChunkName: "components/finance-create-lao-self-managed" */).then(c => wrapFunctional(c.default || c))
export const FinanceCreatePaymentProviderDialog = () => import('../../components/finance/CreatePaymentProviderDialog.vue' /* webpackChunkName: "components/finance-create-payment-provider-dialog" */).then(c => wrapFunctional(c.default || c))
export const FinanceDepositPageInternational = () => import('../../components/finance/DepositPageInternational.vue' /* webpackChunkName: "components/finance-deposit-page-international" */).then(c => wrapFunctional(c.default || c))
export const FinanceDepositPageLao = () => import('../../components/finance/DepositPageLao.vue' /* webpackChunkName: "components/finance-deposit-page-lao" */).then(c => wrapFunctional(c.default || c))
export const FinanceEditInternationalManaged = () => import('../../components/finance/EditInternationalManaged.vue' /* webpackChunkName: "components/finance-edit-international-managed" */).then(c => wrapFunctional(c.default || c))
export const FinanceEditLaoSelfManaged = () => import('../../components/finance/EditLaoSelfManaged.vue' /* webpackChunkName: "components/finance-edit-lao-self-managed" */).then(c => wrapFunctional(c.default || c))
export const FinanceEditPaymentProviderDialog = () => import('../../components/finance/EditPaymentProviderDialog.vue' /* webpackChunkName: "components/finance-edit-payment-provider-dialog" */).then(c => wrapFunctional(c.default || c))
export const FinanceNewWithdrawal = () => import('../../components/finance/NewWithdrawal.vue' /* webpackChunkName: "components/finance-new-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const FinancePendingWithdrawal = () => import('../../components/finance/PendingWithdrawal.vue' /* webpackChunkName: "components/finance-pending-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const FrontendLanguage = () => import('../../components/frontend/Language.vue' /* webpackChunkName: "components/frontend-language" */).then(c => wrapFunctional(c.default || c))
export const FrontendLanguageSwitch = () => import('../../components/frontend/LanguageSwitch.vue' /* webpackChunkName: "components/frontend-language-switch" */).then(c => wrapFunctional(c.default || c))
export const FrontendLoadingItem = () => import('../../components/frontend/LoadingItem.vue' /* webpackChunkName: "components/frontend-loading-item" */).then(c => wrapFunctional(c.default || c))
export const FrontendUpdateLandingPrimary = () => import('../../components/frontend/UpdateLandingPrimary.vue' /* webpackChunkName: "components/frontend-update-landing-primary" */).then(c => wrapFunctional(c.default || c))
export const LanguageStringsPencilEdit = () => import('../../components/language-strings/PencilEdit.vue' /* webpackChunkName: "components/language-strings-pencil-edit" */).then(c => wrapFunctional(c.default || c))
export const ManualAdjustmentCreateManualAdjustment = () => import('../../components/manual-adjustment/CreateManualAdjustment.vue' /* webpackChunkName: "components/manual-adjustment-create-manual-adjustment" */).then(c => wrapFunctional(c.default || c))
export const NavbarChangeLanguage = () => import('../../components/navbar/ChangeLanguage.vue' /* webpackChunkName: "components/navbar-change-language" */).then(c => wrapFunctional(c.default || c))
export const NavbarDarkModeSwitch = () => import('../../components/navbar/DarkModeSwitch.vue' /* webpackChunkName: "components/navbar-dark-mode-switch" */).then(c => wrapFunctional(c.default || c))
export const NavbarMessageDropdown = () => import('../../components/navbar/MessageDropdown.vue' /* webpackChunkName: "components/navbar-message-dropdown" */).then(c => wrapFunctional(c.default || c))
export const NavbarNotificationList = () => import('../../components/navbar/NotificationList.vue' /* webpackChunkName: "components/navbar-notification-list" */).then(c => wrapFunctional(c.default || c))
export const NavbarOpenSettings = () => import('../../components/navbar/OpenSettings.vue' /* webpackChunkName: "components/navbar-open-settings" */).then(c => wrapFunctional(c.default || c))
export const NavbarProfileDropdown = () => import('../../components/navbar/ProfileDropdown.vue' /* webpackChunkName: "components/navbar-profile-dropdown" */).then(c => wrapFunctional(c.default || c))
export const NavbarVersionChip = () => import('../../components/navbar/VersionChip.vue' /* webpackChunkName: "components/navbar-version-chip" */).then(c => wrapFunctional(c.default || c))
export const PagesAllSummaryInternational = () => import('../../components/pages/AllSummaryInternational.vue' /* webpackChunkName: "components/pages-all-summary-international" */).then(c => wrapFunctional(c.default || c))
export const PagesAllSummaryInternational1 = () => import('../../components/pages/AllSummaryInternational1.vue' /* webpackChunkName: "components/pages-all-summary-international1" */).then(c => wrapFunctional(c.default || c))
export const PagesAllSummaryLao = () => import('../../components/pages/AllSummaryLao.vue' /* webpackChunkName: "components/pages-all-summary-lao" */).then(c => wrapFunctional(c.default || c))
export const PagesDepositReportInternational = () => import('../../components/pages/DepositReportInternational.vue' /* webpackChunkName: "components/pages-deposit-report-international" */).then(c => wrapFunctional(c.default || c))
export const PagesDepositReportLao = () => import('../../components/pages/DepositReportLao.vue' /* webpackChunkName: "components/pages-deposit-report-lao" */).then(c => wrapFunctional(c.default || c))
export const PagesWithdrawalReportInternational = () => import('../../components/pages/WithdrawalReportInternational.vue' /* webpackChunkName: "components/pages-withdrawal-report-international" */).then(c => wrapFunctional(c.default || c))
export const PagesWithdrawalReportLao = () => import('../../components/pages/WithdrawalReportLao.vue' /* webpackChunkName: "components/pages-withdrawal-report-lao" */).then(c => wrapFunctional(c.default || c))
export const PlayerAddNote = () => import('../../components/player/AddNote.vue' /* webpackChunkName: "components/player-add-note" */).then(c => wrapFunctional(c.default || c))
export const PlayerBankAccount = () => import('../../components/player/BankAccount.vue' /* webpackChunkName: "components/player-bank-account" */).then(c => wrapFunctional(c.default || c))
export const PlayerCreatePlayerForInternational = () => import('../../components/player/CreatePlayerForInternational.vue' /* webpackChunkName: "components/player-create-player-for-international" */).then(c => wrapFunctional(c.default || c))
export const PlayerCreatePlayerForLao = () => import('../../components/player/CreatePlayerForLao.vue' /* webpackChunkName: "components/player-create-player-for-lao" */).then(c => wrapFunctional(c.default || c))
export const PlayerCreatedPlayerCard = () => import('../../components/player/CreatedPlayerCard.vue' /* webpackChunkName: "components/player-created-player-card" */).then(c => wrapFunctional(c.default || c))
export const PlayerCryptoWallet = () => import('../../components/player/CryptoWallet.vue' /* webpackChunkName: "components/player-crypto-wallet" */).then(c => wrapFunctional(c.default || c))
export const PlayerFinanceInformation = () => import('../../components/player/FinanceInformation.vue' /* webpackChunkName: "components/player-finance-information" */).then(c => wrapFunctional(c.default || c))
export const PlayerIdSearch = () => import('../../components/player/IdSearch.vue' /* webpackChunkName: "components/player-id-search" */).then(c => wrapFunctional(c.default || c))
export const PlayerPersonalInformation = () => import('../../components/player/PersonalInformation.vue' /* webpackChunkName: "components/player-personal-information" */).then(c => wrapFunctional(c.default || c))
export const PlayerHeatmap = () => import('../../components/player/PlayerHeatmap.vue' /* webpackChunkName: "components/player-heatmap" */).then(c => wrapFunctional(c.default || c))
export const PlayerInteraction = () => import('../../components/player/PlayerInteraction.vue' /* webpackChunkName: "components/player-interaction" */).then(c => wrapFunctional(c.default || c))
export const PlayerInteractionTable = () => import('../../components/player/PlayerInteractionTable.vue' /* webpackChunkName: "components/player-interaction-table" */).then(c => wrapFunctional(c.default || c))
export const PlayerSignupInformation = () => import('../../components/player/SignupInformation.vue' /* webpackChunkName: "components/player-signup-information" */).then(c => wrapFunctional(c.default || c))
export const ProfileGameLogs = () => import('../../components/profile/GameLogs.vue' /* webpackChunkName: "components/profile-game-logs" */).then(c => wrapFunctional(c.default || c))
export const ProfileIpLogs = () => import('../../components/profile/IpLogs.vue' /* webpackChunkName: "components/profile-ip-logs" */).then(c => wrapFunctional(c.default || c))
export const ProfileLoginLogs = () => import('../../components/profile/LoginLogs.vue' /* webpackChunkName: "components/profile-login-logs" */).then(c => wrapFunctional(c.default || c))
export const ProfileTransactionLogs = () => import('../../components/profile/TransactionLogs.vue' /* webpackChunkName: "components/profile-transaction-logs" */).then(c => wrapFunctional(c.default || c))
export const ProfileTransactionStatus = () => import('../../components/profile/TransactionStatus.vue' /* webpackChunkName: "components/profile-transaction-status" */).then(c => wrapFunctional(c.default || c))
export const ProfileTransferBalanceLogs = () => import('../../components/profile/TransferBalanceLogs.vue' /* webpackChunkName: "components/profile-transfer-balance-logs" */).then(c => wrapFunctional(c.default || c))
export const ProfileTransferLogs = () => import('../../components/profile/TransferLogs.vue' /* webpackChunkName: "components/profile-transfer-logs" */).then(c => wrapFunctional(c.default || c))
export const PromotionDragAndDropFile = () => import('../../components/promotion/DragAndDropFile.vue' /* webpackChunkName: "components/promotion-drag-and-drop-file" */).then(c => wrapFunctional(c.default || c))
export const ProvidersDisableProvider = () => import('../../components/providers/DisableProvider.vue' /* webpackChunkName: "components/providers-disable-provider" */).then(c => wrapFunctional(c.default || c))
export const RebateClaimByPlayer = () => import('../../components/rebate/ClaimByPlayer.vue' /* webpackChunkName: "components/rebate-claim-by-player" */).then(c => wrapFunctional(c.default || c))
export const RebateCurrentSetting = () => import('../../components/rebate/CurrentSetting.vue' /* webpackChunkName: "components/rebate-current-setting" */).then(c => wrapFunctional(c.default || c))
export const RebateFutureSetting = () => import('../../components/rebate/FutureSetting.vue' /* webpackChunkName: "components/rebate-future-setting" */).then(c => wrapFunctional(c.default || c))
export const ReferralAutomatedPlayer = () => import('../../components/referral/AutomatedPlayer.vue' /* webpackChunkName: "components/referral-automated-player" */).then(c => wrapFunctional(c.default || c))
export const ReferralClaimByPlayer = () => import('../../components/referral/ClaimByPlayer.vue' /* webpackChunkName: "components/referral-claim-by-player" */).then(c => wrapFunctional(c.default || c))
export const ReferralClaimTypeFilters = () => import('../../components/referral/ClaimTypeFilters.vue' /* webpackChunkName: "components/referral-claim-type-filters" */).then(c => wrapFunctional(c.default || c))
export const ReferralCurrentSetting = () => import('../../components/referral/CurrentSetting.vue' /* webpackChunkName: "components/referral-current-setting" */).then(c => wrapFunctional(c.default || c))
export const ReferralFutureSetting = () => import('../../components/referral/FutureSetting.vue' /* webpackChunkName: "components/referral-future-setting" */).then(c => wrapFunctional(c.default || c))
export const ReferralStartEndRange = () => import('../../components/referral/StartEndRange.vue' /* webpackChunkName: "components/referral-start-end-range" */).then(c => wrapFunctional(c.default || c))
export const ReportsCompanyBrandPerformance = () => import('../../components/reports/CompanyBrandPerformance.vue' /* webpackChunkName: "components/reports-company-brand-performance" */).then(c => wrapFunctional(c.default || c))
export const ReportsCompanyOverallPerformance = () => import('../../components/reports/CompanyOverallPerformance.vue' /* webpackChunkName: "components/reports-company-overall-performance" */).then(c => wrapFunctional(c.default || c))
export const ReportsCompanyWebPerformance = () => import('../../components/reports/CompanyWebPerformance.vue' /* webpackChunkName: "components/reports-company-web-performance" */).then(c => wrapFunctional(c.default || c))
export const ReportsHighestDeposit = () => import('../../components/reports/HighestDeposit.vue' /* webpackChunkName: "components/reports-highest-deposit" */).then(c => wrapFunctional(c.default || c))
export const ReportsHighestWithdrawal = () => import('../../components/reports/HighestWithdrawal.vue' /* webpackChunkName: "components/reports-highest-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const RolesUpdateStatusButton = () => import('../../components/roles/UpdateStatusButton.vue' /* webpackChunkName: "components/roles-update-status-button" */).then(c => wrapFunctional(c.default || c))
export const SettingDepositTypeCard = () => import('../../components/setting/DepositTypeCard.vue' /* webpackChunkName: "components/setting-deposit-type-card" */).then(c => wrapFunctional(c.default || c))
export const SettingEditCurrencyDialog = () => import('../../components/setting/EditCurrencyDialog.vue' /* webpackChunkName: "components/setting-edit-currency-dialog" */).then(c => wrapFunctional(c.default || c))
export const SettingPaymentMethod = () => import('../../components/setting/PaymentMethod.vue' /* webpackChunkName: "components/setting-payment-method" */).then(c => wrapFunctional(c.default || c))
export const SettingRegistrationOption = () => import('../../components/setting/RegistrationOption.vue' /* webpackChunkName: "components/setting-registration-option" */).then(c => wrapFunctional(c.default || c))
export const SettingUpdateCurrencyStatus = () => import('../../components/setting/UpdateCurrencyStatus.vue' /* webpackChunkName: "components/setting-update-currency-status" */).then(c => wrapFunctional(c.default || c))
export const VuetifyPhoneInput = () => import('../../components/vuetify/PhoneInput.vue' /* webpackChunkName: "components/vuetify-phone-input" */).then(c => wrapFunctional(c.default || c))
export const AgentCreateDomainForm = () => import('../../components/agent/create/DomainForm.vue' /* webpackChunkName: "components/agent-create-domain-form" */).then(c => wrapFunctional(c.default || c))
export const AgentCreateRelationshipValueForm = () => import('../../components/agent/create/RelationshipValueForm.vue' /* webpackChunkName: "components/agent-create-relationship-value-form" */).then(c => wrapFunctional(c.default || c))
export const AgentCreateSuccessDialog = () => import('../../components/agent/create/SuccessDialog.vue' /* webpackChunkName: "components/agent-create-success-dialog" */).then(c => wrapFunctional(c.default || c))
export const AgentCreateSuccessfulDetail = () => import('../../components/agent/create/SuccessfulDetail.vue' /* webpackChunkName: "components/agent-create-successful-detail" */).then(c => wrapFunctional(c.default || c))
export const DepositAgentsLockDepositRequest = () => import('../../components/deposit/agents/LockDepositRequest.vue' /* webpackChunkName: "components/deposit-agents-lock-deposit-request" */).then(c => wrapFunctional(c.default || c))
export const DepositAgentsNewDeposit = () => import('../../components/deposit/agents/NewDeposit.vue' /* webpackChunkName: "components/deposit-agents-new-deposit" */).then(c => wrapFunctional(c.default || c))
export const DepositAgentsPendingDeposit = () => import('../../components/deposit/agents/PendingDeposit.vue' /* webpackChunkName: "components/deposit-agents-pending-deposit" */).then(c => wrapFunctional(c.default || c))
export const FinanceCurrenciesSwitchStatus = () => import('../../components/finance/currencies/SwitchStatus.vue' /* webpackChunkName: "components/finance-currencies-switch-status" */).then(c => wrapFunctional(c.default || c))
export const FinanceManualDepositInternationalDeposit = () => import('../../components/finance/manual-deposit/InternationalDeposit.vue' /* webpackChunkName: "components/finance-manual-deposit-international-deposit" */).then(c => wrapFunctional(c.default || c))
export const FinanceManualDepositLaoDeposit = () => import('../../components/finance/manual-deposit/LaoDeposit.vue' /* webpackChunkName: "components/finance-manual-deposit-lao-deposit" */).then(c => wrapFunctional(c.default || c))
export const FinanceSelfManagedEnableDisabledButton = () => import('../../components/finance/self-managed/EnableDisabledButton.vue' /* webpackChunkName: "components/finance-self-managed-enable-disabled-button" */).then(c => wrapFunctional(c.default || c))
export const FinanceWithdrawalsConditionToClaim = () => import('../../components/finance/withdrawals/ConditionToClaim.vue' /* webpackChunkName: "components/finance-withdrawals-condition-to-claim" */).then(c => wrapFunctional(c.default || c))
export const FinanceWithdrawalsConditionToComplete = () => import('../../components/finance/withdrawals/ConditionToComplete.vue' /* webpackChunkName: "components/finance-withdrawals-condition-to-complete" */).then(c => wrapFunctional(c.default || c))
export const FinanceWithdrawalsConditionWithdrawal = () => import('../../components/finance/withdrawals/ConditionWithdrawal.vue' /* webpackChunkName: "components/finance-withdrawals-condition-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const FinanceWithdrawalsPromoCode = () => import('../../components/finance/withdrawals/PromoCode.vue' /* webpackChunkName: "components/finance-withdrawals-promo-code" */).then(c => wrapFunctional(c.default || c))
export const FinanceWithdrawalsPromotion = () => import('../../components/finance/withdrawals/Promotion.vue' /* webpackChunkName: "components/finance-withdrawals-promotion" */).then(c => wrapFunctional(c.default || c))
export const FinanceWithdrawalsRewardToPlayers = () => import('../../components/finance/withdrawals/RewardToPlayers.vue' /* webpackChunkName: "components/finance-withdrawals-reward-to-players" */).then(c => wrapFunctional(c.default || c))
export const FrontendAnnouncementsCreateAnnouncement = () => import('../../components/frontend/announcements/CreateAnnouncement.vue' /* webpackChunkName: "components/frontend-announcements-create-announcement" */).then(c => wrapFunctional(c.default || c))
export const FrontendAnnouncementsDeleteButton = () => import('../../components/frontend/announcements/DeleteButton.vue' /* webpackChunkName: "components/frontend-announcements-delete-button" */).then(c => wrapFunctional(c.default || c))
export const FrontendAnnouncementsEditAnnouncement = () => import('../../components/frontend/announcements/EditAnnouncement.vue' /* webpackChunkName: "components/frontend-announcements-edit-announcement" */).then(c => wrapFunctional(c.default || c))
export const FrontendAnnouncementsEditButton = () => import('../../components/frontend/announcements/EditButton.vue' /* webpackChunkName: "components/frontend-announcements-edit-button" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsActionButtons = () => import('../../components/frontend/cms/ActionButtons.vue' /* webpackChunkName: "components/frontend-cms-action-buttons" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsCancelAndSaveButtons = () => import('../../components/frontend/cms/CancelAndSaveButtons.vue' /* webpackChunkName: "components/frontend-cms-cancel-and-save-buttons" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsLinkDisplay = () => import('../../components/frontend/cms/LinkDisplay.vue' /* webpackChunkName: "components/frontend-cms-link-display" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsPreviewImage = () => import('../../components/frontend/cms/PreviewImage.vue' /* webpackChunkName: "components/frontend-cms-preview-image" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsViewMoreText = () => import('../../components/frontend/cms/ViewMoreText.vue' /* webpackChunkName: "components/frontend-cms-view-more-text" */).then(c => wrapFunctional(c.default || c))
export const PagesInternationalDepositPlayerList = () => import('../../components/pages/international/DepositPlayerList.vue' /* webpackChunkName: "components/pages-international-deposit-player-list" */).then(c => wrapFunctional(c.default || c))
export const PagesInternationalLosingList = () => import('../../components/pages/international/LosingList.vue' /* webpackChunkName: "components/pages-international-losing-list" */).then(c => wrapFunctional(c.default || c))
export const PagesInternationalManualAmount = () => import('../../components/pages/international/ManualAmount.vue' /* webpackChunkName: "components/pages-international-manual-amount" */).then(c => wrapFunctional(c.default || c))
export const PagesInternationalManualCount = () => import('../../components/pages/international/ManualCount.vue' /* webpackChunkName: "components/pages-international-manual-count" */).then(c => wrapFunctional(c.default || c))
export const PagesInternationalOverallReport = () => import('../../components/pages/international/OverallReport.vue' /* webpackChunkName: "components/pages-international-overall-report" */).then(c => wrapFunctional(c.default || c))
export const PagesInternationalPlayerList = () => import('../../components/pages/international/PlayerList.vue' /* webpackChunkName: "components/pages-international-player-list" */).then(c => wrapFunctional(c.default || c))
export const PagesInternationalRewardCount = () => import('../../components/pages/international/RewardCount.vue' /* webpackChunkName: "components/pages-international-reward-count" */).then(c => wrapFunctional(c.default || c))
export const PagesInternationalTotalCount = () => import('../../components/pages/international/TotalCount.vue' /* webpackChunkName: "components/pages-international-total-count" */).then(c => wrapFunctional(c.default || c))
export const PagesInternationalTotalSource = () => import('../../components/pages/international/TotalSource.vue' /* webpackChunkName: "components/pages-international-total-source" */).then(c => wrapFunctional(c.default || c))
export const PagesInternationalWinningList = () => import('../../components/pages/international/WinningList.vue' /* webpackChunkName: "components/pages-international-winning-list" */).then(c => wrapFunctional(c.default || c))
export const PagesLaoCurrencyBox = () => import('../../components/pages/lao/CurrencyBox.vue' /* webpackChunkName: "components/pages-lao-currency-box" */).then(c => wrapFunctional(c.default || c))
export const PagesLaoDepositPlayerList = () => import('../../components/pages/lao/DepositPlayerList.vue' /* webpackChunkName: "components/pages-lao-deposit-player-list" */).then(c => wrapFunctional(c.default || c))
export const PagesLaoManualAmount = () => import('../../components/pages/lao/ManualAmount.vue' /* webpackChunkName: "components/pages-lao-manual-amount" */).then(c => wrapFunctional(c.default || c))
export const PagesLaoManualCount = () => import('../../components/pages/lao/ManualCount.vue' /* webpackChunkName: "components/pages-lao-manual-count" */).then(c => wrapFunctional(c.default || c))
export const PagesLaoOverallReport = () => import('../../components/pages/lao/OverallReport.vue' /* webpackChunkName: "components/pages-lao-overall-report" */).then(c => wrapFunctional(c.default || c))
export const PagesLaoPlayerList = () => import('../../components/pages/lao/PlayerList.vue' /* webpackChunkName: "components/pages-lao-player-list" */).then(c => wrapFunctional(c.default || c))
export const PagesLaoRewardCount = () => import('../../components/pages/lao/RewardCount.vue' /* webpackChunkName: "components/pages-lao-reward-count" */).then(c => wrapFunctional(c.default || c))
export const PagesLaoTotalCount = () => import('../../components/pages/lao/TotalCount.vue' /* webpackChunkName: "components/pages-lao-total-count" */).then(c => wrapFunctional(c.default || c))
export const PagesLaoTotalSource = () => import('../../components/pages/lao/TotalSource.vue' /* webpackChunkName: "components/pages-lao-total-source" */).then(c => wrapFunctional(c.default || c))
export const PagesOperatorDepositPlayerList = () => import('../../components/pages/operator/DepositPlayerList.vue' /* webpackChunkName: "components/pages-operator-deposit-player-list" */).then(c => wrapFunctional(c.default || c))
export const PagesOperatorManualAmount = () => import('../../components/pages/operator/ManualAmount.vue' /* webpackChunkName: "components/pages-operator-manual-amount" */).then(c => wrapFunctional(c.default || c))
export const PagesOperatorManualCount = () => import('../../components/pages/operator/ManualCount.vue' /* webpackChunkName: "components/pages-operator-manual-count" */).then(c => wrapFunctional(c.default || c))
export const PagesOperatorOverallReport = () => import('../../components/pages/operator/OverallReport.vue' /* webpackChunkName: "components/pages-operator-overall-report" */).then(c => wrapFunctional(c.default || c))
export const PagesOperatorPlayerList = () => import('../../components/pages/operator/PlayerList.vue' /* webpackChunkName: "components/pages-operator-player-list" */).then(c => wrapFunctional(c.default || c))
export const PagesOperatorRewardCount = () => import('../../components/pages/operator/RewardCount.vue' /* webpackChunkName: "components/pages-operator-reward-count" */).then(c => wrapFunctional(c.default || c))
export const PagesOperatorTotalCount = () => import('../../components/pages/operator/TotalCount.vue' /* webpackChunkName: "components/pages-operator-total-count" */).then(c => wrapFunctional(c.default || c))
export const PagesOperatorTotalSource = () => import('../../components/pages/operator/TotalSource.vue' /* webpackChunkName: "components/pages-operator-total-source" */).then(c => wrapFunctional(c.default || c))
export const PlayerDetailAllLogs = () => import('../../components/player/detail/AllLogs.vue' /* webpackChunkName: "components/player-detail-all-logs" */).then(c => wrapFunctional(c.default || c))
export const PlayerDetailMainInfo = () => import('../../components/player/detail/MainInfo.vue' /* webpackChunkName: "components/player-detail-main-info" */).then(c => wrapFunctional(c.default || c))
export const PlayerMissingPlayersRecentDeposits = () => import('../../components/player/missing-players/RecentDeposits.vue' /* webpackChunkName: "components/player-missing-players-recent-deposits" */).then(c => wrapFunctional(c.default || c))
export const PlayerMissingPlayersWithoutDeposits = () => import('../../components/player/missing-players/WithoutDeposits.vue' /* webpackChunkName: "components/player-missing-players-without-deposits" */).then(c => wrapFunctional(c.default || c))
export const PromotionButtonsDeletePromotion = () => import('../../components/promotion/buttons/DeletePromotion.vue' /* webpackChunkName: "components/promotion-buttons-delete-promotion" */).then(c => wrapFunctional(c.default || c))
export const PromotionConditionsClaimBonus = () => import('../../components/promotion/conditions/ClaimBonus.vue' /* webpackChunkName: "components/promotion-conditions-claim-bonus" */).then(c => wrapFunctional(c.default || c))
export const PromotionConditionsClaimBonusInputs = () => import('../../components/promotion/conditions/ClaimBonusInputs.vue' /* webpackChunkName: "components/promotion-conditions-claim-bonus-inputs" */).then(c => wrapFunctional(c.default || c))
export const PromotionConditionsCompleteBonus = () => import('../../components/promotion/conditions/CompleteBonus.vue' /* webpackChunkName: "components/promotion-conditions-complete-bonus" */).then(c => wrapFunctional(c.default || c))
export const PromotionConditionsCompleteBonusInputs = () => import('../../components/promotion/conditions/CompleteBonusInputs.vue' /* webpackChunkName: "components/promotion-conditions-complete-bonus-inputs" */).then(c => wrapFunctional(c.default || c))
export const PromotionConditionsHappyHour = () => import('../../components/promotion/conditions/HappyHour.vue' /* webpackChunkName: "components/promotion-conditions-happy-hour" */).then(c => wrapFunctional(c.default || c))
export const PromotionConditionsRewardToPlayer = () => import('../../components/promotion/conditions/RewardToPlayer.vue' /* webpackChunkName: "components/promotion-conditions-reward-to-player" */).then(c => wrapFunctional(c.default || c))
export const PromotionConditionsWithdrawalClaim = () => import('../../components/promotion/conditions/WithdrawalClaim.vue' /* webpackChunkName: "components/promotion-conditions-withdrawal-claim" */).then(c => wrapFunctional(c.default || c))
export const PromotionConditionsWithdrawalClaimInputs = () => import('../../components/promotion/conditions/WithdrawalClaimInputs.vue' /* webpackChunkName: "components/promotion-conditions-withdrawal-claim-inputs" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditArticleContent1 = () => import('../../components/frontend/cms/component-edit/ArticleContent1.vue' /* webpackChunkName: "components/frontend-cms-component-edit-article-content1" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditArticleContent2 = () => import('../../components/frontend/cms/component-edit/ArticleContent2.vue' /* webpackChunkName: "components/frontend-cms-component-edit-article-content2" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditArticleContent3 = () => import('../../components/frontend/cms/component-edit/ArticleContent3.vue' /* webpackChunkName: "components/frontend-cms-component-edit-article-content3" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditArticleContent4 = () => import('../../components/frontend/cms/component-edit/ArticleContent4.vue' /* webpackChunkName: "components/frontend-cms-component-edit-article-content4" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditBannerDeposit = () => import('../../components/frontend/cms/component-edit/BannerDeposit.vue' /* webpackChunkName: "components/frontend-cms-component-edit-banner-deposit" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditDepositInstructionContent = () => import('../../components/frontend/cms/component-edit/DepositInstructionContent.vue' /* webpackChunkName: "components/frontend-cms-component-edit-deposit-instruction-content" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditDesktopBackground = () => import('../../components/frontend/cms/component-edit/DesktopBackground.vue' /* webpackChunkName: "components/frontend-cms-component-edit-desktop-background" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditDesktopLotteryWinners = () => import('../../components/frontend/cms/component-edit/DesktopLotteryWinners.vue' /* webpackChunkName: "components/frontend-cms-component-edit-desktop-lottery-winners" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditDesktopSplashScreen = () => import('../../components/frontend/cms/component-edit/DesktopSplashScreen.vue' /* webpackChunkName: "components/frontend-cms-component-edit-desktop-splash-screen" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditMenubar1 = () => import('../../components/frontend/cms/component-edit/EditMenubar1.vue' /* webpackChunkName: "components/frontend-cms-component-edit-menubar1" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditFavicon = () => import('../../components/frontend/cms/component-edit/Favicon.vue' /* webpackChunkName: "components/frontend-cms-component-edit-favicon" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditFeaturedContent1 = () => import('../../components/frontend/cms/component-edit/FeaturedContent1.vue' /* webpackChunkName: "components/frontend-cms-component-edit-featured-content1" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditFeaturedContent2 = () => import('../../components/frontend/cms/component-edit/FeaturedContent2.vue' /* webpackChunkName: "components/frontend-cms-component-edit-featured-content2" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditFeaturedContent3 = () => import('../../components/frontend/cms/component-edit/FeaturedContent3.vue' /* webpackChunkName: "components/frontend-cms-component-edit-featured-content3" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditFloatingIcon1 = () => import('../../components/frontend/cms/component-edit/FloatingIcon1.vue' /* webpackChunkName: "components/frontend-cms-component-edit-floating-icon1" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditFloatingIcon2 = () => import('../../components/frontend/cms/component-edit/FloatingIcon2.vue' /* webpackChunkName: "components/frontend-cms-component-edit-floating-icon2" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditFooterLogo = () => import('../../components/frontend/cms/component-edit/FooterLogo.vue' /* webpackChunkName: "components/frontend-cms-component-edit-footer-logo" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditGamingInformation = () => import('../../components/frontend/cms/component-edit/GamingInformation.vue' /* webpackChunkName: "components/frontend-cms-component-edit-gaming-information" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditHeaderLogo = () => import('../../components/frontend/cms/component-edit/HeaderLogo.vue' /* webpackChunkName: "components/frontend-cms-component-edit-header-logo" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditJackpotContent = () => import('../../components/frontend/cms/component-edit/JackpotContent.vue' /* webpackChunkName: "components/frontend-cms-component-edit-jackpot-content" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditMobileBackground = () => import('../../components/frontend/cms/component-edit/MobileBackground.vue' /* webpackChunkName: "components/frontend-cms-component-edit-mobile-background" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditOnHoldBalanceInstructionalContent = () => import('../../components/frontend/cms/component-edit/OnHoldBalanceInstructionalContent.vue' /* webpackChunkName: "components/frontend-cms-component-edit-on-hold-balance-instructional-content" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditPartnerIcon = () => import('../../components/frontend/cms/component-edit/PartnerIcon.vue' /* webpackChunkName: "components/frontend-cms-component-edit-partner-icon" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditPromotionContent = () => import('../../components/frontend/cms/component-edit/PromotionContent.vue' /* webpackChunkName: "components/frontend-cms-component-edit-promotion-content" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditRecentWinners = () => import('../../components/frontend/cms/component-edit/RecentWinners.vue' /* webpackChunkName: "components/frontend-cms-component-edit-recent-winners" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditRegistrationImage = () => import('../../components/frontend/cms/component-edit/RegistrationImage.vue' /* webpackChunkName: "components/frontend-cms-component-edit-registration-image" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditSliderContentDesktop = () => import('../../components/frontend/cms/component-edit/SliderContentDesktop.vue' /* webpackChunkName: "components/frontend-cms-component-edit-slider-content-desktop" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditSliderContentMobile = () => import('../../components/frontend/cms/component-edit/SliderContentMobile.vue' /* webpackChunkName: "components/frontend-cms-component-edit-slider-content-mobile" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditTermsAndConditions = () => import('../../components/frontend/cms/component-edit/TermsAndConditions.vue' /* webpackChunkName: "components/frontend-cms-component-edit-terms-and-conditions" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditTopDepositRanking = () => import('../../components/frontend/cms/component-edit/TopDepositRanking.vue' /* webpackChunkName: "components/frontend-cms-component-edit-top-deposit-ranking" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditTopWinners = () => import('../../components/frontend/cms/component-edit/TopWinners.vue' /* webpackChunkName: "components/frontend-cms-component-edit-top-winners" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditUnluckyWinners = () => import('../../components/frontend/cms/component-edit/UnluckyWinners.vue' /* webpackChunkName: "components/frontend-cms-component-edit-unlucky-winners" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditVideoContent1 = () => import('../../components/frontend/cms/component-edit/VideoContent1.vue' /* webpackChunkName: "components/frontend-cms-component-edit-video-content1" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentEditVideoContent2 = () => import('../../components/frontend/cms/component-edit/VideoContent2.vue' /* webpackChunkName: "components/frontend-cms-component-edit-video-content2" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypeArticleContent1 = () => import('../../components/frontend/cms/component-type/ArticleContent1.vue' /* webpackChunkName: "components/frontend-cms-component-type-article-content1" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypeArticleContent2 = () => import('../../components/frontend/cms/component-type/ArticleContent2.vue' /* webpackChunkName: "components/frontend-cms-component-type-article-content2" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypeArticleContent3 = () => import('../../components/frontend/cms/component-type/ArticleContent3.vue' /* webpackChunkName: "components/frontend-cms-component-type-article-content3" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypeArticleContent4 = () => import('../../components/frontend/cms/component-type/ArticleContent4.vue' /* webpackChunkName: "components/frontend-cms-component-type-article-content4" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypeBannerDeposit = () => import('../../components/frontend/cms/component-type/BannerDeposit.vue' /* webpackChunkName: "components/frontend-cms-component-type-banner-deposit" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypeFeaturedContent1 = () => import('../../components/frontend/cms/component-type/FeaturedContent1.vue' /* webpackChunkName: "components/frontend-cms-component-type-featured-content1" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypeFeaturedContent2 = () => import('../../components/frontend/cms/component-type/FeaturedContent2.vue' /* webpackChunkName: "components/frontend-cms-component-type-featured-content2" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypeFeaturedContent3 = () => import('../../components/frontend/cms/component-type/FeaturedContent3.vue' /* webpackChunkName: "components/frontend-cms-component-type-featured-content3" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypeFeaturedContent4 = () => import('../../components/frontend/cms/component-type/FeaturedContent4.vue' /* webpackChunkName: "components/frontend-cms-component-type-featured-content4" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypePartnerIcon = () => import('../../components/frontend/cms/component-type/PartnerIcon.vue' /* webpackChunkName: "components/frontend-cms-component-type-partner-icon" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypeTermsAndConditions = () => import('../../components/frontend/cms/component-type/TermsAndConditions.vue' /* webpackChunkName: "components/frontend-cms-component-type-terms-and-conditions" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypeVideoContent1 = () => import('../../components/frontend/cms/component-type/VideoContent1.vue' /* webpackChunkName: "components/frontend-cms-component-type-video-content1" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypeVideoContent2 = () => import('../../components/frontend/cms/component-type/VideoContent2.vue' /* webpackChunkName: "components/frontend-cms-component-type-video-content2" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentsAddComponentTab = () => import('../../components/frontend/cms/components/AddComponentTab.vue' /* webpackChunkName: "components/frontend-cms-components-add-component-tab" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentsArticleList = () => import('../../components/frontend/cms/components/ArticleList.vue' /* webpackChunkName: "components/frontend-cms-components-article-list" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentsCardDivider = () => import('../../components/frontend/cms/components/CardDivider.vue' /* webpackChunkName: "components/frontend-cms-components-card-divider" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentsCurrencyItem = () => import('../../components/frontend/cms/components/CurrencyItem.vue' /* webpackChunkName: "components/frontend-cms-components-currency-item" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentsEditComponentTab = () => import('../../components/frontend/cms/components/EditComponentTab.vue' /* webpackChunkName: "components/frontend-cms-components-edit-component-tab" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentsGameDetail = () => import('../../components/frontend/cms/components/GameDetail.vue' /* webpackChunkName: "components/frontend-cms-components-game-detail" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentsRemoveCmsItem = () => import('../../components/frontend/cms/components/RemoveCmsItem.vue' /* webpackChunkName: "components/frontend-cms-components-remove-cms-item" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentsTitleAndDescription = () => import('../../components/frontend/cms/components/TitleAndDescription.vue' /* webpackChunkName: "components/frontend-cms-components-title-and-description" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayArticleContent1 = () => import('../../components/frontend/cms/image-table-display/ArticleContent1.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-article-content1" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayArticleContent2 = () => import('../../components/frontend/cms/image-table-display/ArticleContent2.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-article-content2" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayArticleContent3 = () => import('../../components/frontend/cms/image-table-display/ArticleContent3.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-article-content3" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayArticleContent4 = () => import('../../components/frontend/cms/image-table-display/ArticleContent4.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-article-content4" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayBannerDeposit = () => import('../../components/frontend/cms/image-table-display/BannerDeposit.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-banner-deposit" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayDepositInstructionContent = () => import('../../components/frontend/cms/image-table-display/DepositInstructionContent.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-deposit-instruction-content" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayDesktopBackground = () => import('../../components/frontend/cms/image-table-display/DesktopBackground.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-desktop-background" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayDesktopLotteryWinners = () => import('../../components/frontend/cms/image-table-display/DesktopLotteryWinners.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-desktop-lottery-winners" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayDesktopMenubarPromotion = () => import('../../components/frontend/cms/image-table-display/DesktopMenubarPromotion.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-desktop-menubar-promotion" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayDesktopSplashScreen = () => import('../../components/frontend/cms/image-table-display/DesktopSplashScreen.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-desktop-splash-screen" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayFavicon = () => import('../../components/frontend/cms/image-table-display/Favicon.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-favicon" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayFeaturedContent1 = () => import('../../components/frontend/cms/image-table-display/FeaturedContent1.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-featured-content1" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayFeaturedContent2 = () => import('../../components/frontend/cms/image-table-display/FeaturedContent2.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-featured-content2" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayFeaturedContent3 = () => import('../../components/frontend/cms/image-table-display/FeaturedContent3.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-featured-content3" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayFloatingIcon1 = () => import('../../components/frontend/cms/image-table-display/FloatingIcon1.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-floating-icon1" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayFloatingIcon2 = () => import('../../components/frontend/cms/image-table-display/FloatingIcon2.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-floating-icon2" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayFooterLogo = () => import('../../components/frontend/cms/image-table-display/FooterLogo.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-footer-logo" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayGamingInformation = () => import('../../components/frontend/cms/image-table-display/GamingInformation.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-gaming-information" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayHeaderLogo = () => import('../../components/frontend/cms/image-table-display/HeaderLogo.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-header-logo" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayJackpotContent = () => import('../../components/frontend/cms/image-table-display/JackpotContent.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-jackpot-content" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayMenubar1 = () => import('../../components/frontend/cms/image-table-display/Menubar1.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-menubar1" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayMobileBackground = () => import('../../components/frontend/cms/image-table-display/MobileBackground.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-mobile-background" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayOnHoldBalanceInstructionalContent = () => import('../../components/frontend/cms/image-table-display/OnHoldBalanceInstructionalContent.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-on-hold-balance-instructional-content" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayPartnerIcon = () => import('../../components/frontend/cms/image-table-display/PartnerIcon.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-partner-icon" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayPromotionContent = () => import('../../components/frontend/cms/image-table-display/PromotionContent.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-promotion-content" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayRecentWinners = () => import('../../components/frontend/cms/image-table-display/RecentWinners.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-recent-winners" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayRegistrationImage = () => import('../../components/frontend/cms/image-table-display/RegistrationImage.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-registration-image" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplaySliderContentDesktop = () => import('../../components/frontend/cms/image-table-display/SliderContentDesktop.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-slider-content-desktop" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplaySliderContentMobile = () => import('../../components/frontend/cms/image-table-display/SliderContentMobile.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-slider-content-mobile" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayTermsAndConditions = () => import('../../components/frontend/cms/image-table-display/TermsAndConditions.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-terms-and-conditions" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayTopDepositRanking = () => import('../../components/frontend/cms/image-table-display/TopDepositRanking.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-top-deposit-ranking" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayTopWinners = () => import('../../components/frontend/cms/image-table-display/TopWinners.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-top-winners" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayUnluckyWinners = () => import('../../components/frontend/cms/image-table-display/UnluckyWinners.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-unlucky-winners" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayVideoContent1 = () => import('../../components/frontend/cms/image-table-display/VideoContent1.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-video-content1" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsImageTableDisplayVideoContent2 = () => import('../../components/frontend/cms/image-table-display/VideoContent2.vue' /* webpackChunkName: "components/frontend-cms-image-table-display-video-content2" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsMenuMenubarTitle = () => import('../../components/frontend/cms/menu/MenubarTitle.vue' /* webpackChunkName: "components/frontend-cms-menu-menubar-title" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypeSubComponentArticleContent = () => import('../../components/frontend/cms/component-type/sub-component/ArticleContent.vue' /* webpackChunkName: "components/frontend-cms-component-type-sub-component-article-content" */).then(c => wrapFunctional(c.default || c))
export const FrontendCmsComponentTypeSubComponentFeaturedContent = () => import('../../components/frontend/cms/component-type/sub-component/FeaturedContent.vue' /* webpackChunkName: "components/frontend-cms-component-type-sub-component-featured-content" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
